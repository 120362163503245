@import '../css/vars';

.header {
  width: 80%;
  max-width: 1040px;
  height: 5rem;
  min-height: 75px;
  margin-bottom: 1vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 1.5rem;
    margin-top: 3.7rem;
    margin-left: 1rem;
    font-family: $font-wielechowski;
    font-display: swap;
    font-weight: lighter;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      opacity: 0.7;
    }
  }

  a {
    display: block;
    color: #333;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #a0a0a0;
    }
  }

  @media (max-width: 815px) {
    h1 {
      font-size: 1.3rem;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    min-height: 50px;
    max-height: 50px;

    h1 {
      margin: 0;
      margin-top: 6px;
      padding: 0.7rem;
    }
  }

  @media (max-width: 800px) {
    align-items: flex-start;

    h1 {
      padding-left: 0;
      margin-top: 5px;
    }
  }
}
