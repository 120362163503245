@import './vars';

@font-face {
  font-family: 'Cinzel';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/static/Cinzel-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/static/Cinzel-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/static/Cinzel-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/static/Cinzel-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel';
  font-weight: 800;
  font-style: normal;
  src: url('../assets/fonts/static/Cinzel-ExtraBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel';
  font-weight: 900;
  font-style: normal;
  src: url('../assets/fonts/static/Cinzel-Black.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel';
  font-weight: 100 900;
  font-style: normal;
  src: url('../assets/fonts/Cinzel-VariableFont_wght.ttf') format('truetype-variations');
  font-named-instance: 'Cinzel Black';
  font-display: swap;
}

@viewport {
  width: device-width;
  height: device-height;
  initial-scale: 1;
  viewport-fit: cover;
}

html {
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #8f8f8f;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #525252;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 78vh;
  overflow: hidden;
  height: 100%;
}

.footer {
  flex-shrink: 0;
}
