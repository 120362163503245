$font-menu: 'Cinzel', serif;
$font-red-stripe: 'Segoe UI', serif;

$font-modal-buttons: 'Cinzel', serif;
$font-wielechowski: 'Cinzel', serif;
$font-title: 'Cinzel', serif;
$font-squares-price: 'Cinzel', serif;
$font-price: 'Cinzel', serif;
$font-content: 'Cinzel', serif;
$font-copyright: 'Cinzel', serif;
$font-gallery-sub-buttons: 'Cinzel', serif;
$font-contact: 'Cinzel', serif;
