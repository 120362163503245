.welcome {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  img {
    max-width: 50%;
    max-height: auto;
    z-index: 999;
  }
}
