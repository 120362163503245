.icons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a {
    max-width: 100px;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    cursor: pointer;

    .icons {
      width: 40px;
      margin: 5px 5px 0px 5px;
      padding: 0;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@media (max-width: 1488px) {
  .icons-container {
    a {
      .icons {
        width: 28px;
      }
    }
  }
}
