@import '../css/vars';

footer {
  width: 100%;
  height: 5rem;
  min-height: 120px;
  margin-top: auto;
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 10px;
    padding: 0;

    @media (max-width: 1488px) {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 800px) {
    min-height: 90px;
  }
}

.copyright {
  font-family: $font-copyright;
  font-weight: 500;
}
