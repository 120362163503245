.spinner-container {
  height: 78vh;
  z-index: 500;

  .spinner {
    position: absolute;
    top: 50%;
    margin-top: -40px;
    margin-left: -40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #ffffff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    z-index: 500;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
